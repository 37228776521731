@tailwind base;
@tailwind components;
@tailwind utilities;

/* Scrollbar personalizado */
.custom-scrollbar::-webkit-scrollbar {
    width: 10px;
}

.custom-scrollbar::-webkit-scrollbar-track {
    background: transparent; 
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 10px; 
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.4);
}

.bg-home-pattern-desktop  {
    background-image: url("https://enlacewebdesign.com/images/bg/bg_desktop.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}  
.bg-home-pattern-tablet  {
    background-image: url("https://enlacewebdesign.com/images/bg/bg_tablet.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}  
.bg-home-pattern-mobile  {
    background-image: url("https://enlacewebdesign.com/images/bg/bg_mobile.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}  